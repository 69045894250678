import React from "react"
import { Link } from "gatsby"

const Nav = () => {
  const navItems = [
    { link: "/music/", text: "Music" },
    { link: "/video/", text: "Video" },
    { link: "/", text: "Bri Stauss" },
    { link: "/photo/", text: "Photo" },
    { link: "/contact/", text: "Contact" },
  ]

  return (
    <nav
      className="fixed w-full z-40"
      style={{ backgroundColor: "rgba(255,255,255, 0.9)" }}
      role="navigation"
      aria-label="main-nav"
    >
      <div className="hidden sm:flex justify-center items-center p-4">
        {navItems.map((item, index) => (
          <Link
            key={index}
            className={`${
              item.text === "Bri Stauss" ? "text-4xl mx-2" : "text-xl mx-4"
            } uppercase hover-underline-animation`}
            to={item.link}
          >
            {item.text}
          </Link>
        ))}
      </div>
    </nav>
  )
}

export default Nav
