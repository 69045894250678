import React, { useEffect } from "react"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"

const Footer = () => {
  const [springProps, setSpringProps] = useSpring(() => ({
    config: { duration: 50 },
    opacity: 0,
  }))
  const copyrightYear = new Date().getFullYear()

  useEffect(() => {
    setSpringProps({ opacity: 1 })
  }, [setSpringProps])

  return (
    <animated.footer style={{ ...springProps }}>
      <div className="p-4 pt-0">
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="hidden sm:block leading-loose text-lg text-center mb-4 lg:mb-0">
            {`Copyright © ${copyrightYear} Bri Stauss | All Rights Reserved`} |
            Built By{" "}
            <a
              href="https://taylorstauss.com"
              target="__blank"
              rel="noreferrer noopener"
            >
              Taylor Stauss
            </a>
          </div>
          <div className="sm:hidden leading-loose text-lg text-center mb-4 lg:mb-0">
            <div>{`Copyright © ${copyrightYear} Bri Stauss`}</div>
            <div>All Rights Reserved</div>
            <div>
              Built By{" "}
              <a
                href="https://taylorstauss.com"
                target="__blank"
                rel="noreferrer noopener"
              >
                Taylor Stauss
              </a>
            </div>
          </div>
          <div className="flex flex-wrap justify-center leading-loose text-lg text-center">
            <Link
              className="mx-2"
              to={`/contact/`}
              style={{ transition: ".5s ease" }}
            >
              Contact
            </Link>
            <Link
              className="mx-2"
              to={`/disclosure/`}
              style={{ transition: ".5s ease" }}
            >
              Disclosure
            </Link>
            <Link
              className="mx-2"
              to={`/privacy/`}
              style={{ transition: ".5s ease" }}
            >
              Privacy
            </Link>
            <Link
              className="mx-2"
              to={`/terms/`}
              style={{ transition: ".5s ease" }}
            >
              Terms
            </Link>
            <a
              href="/sitemap.xml"
              className="mx-2"
              style={{ transition: ".5s ease" }}
            >
              Sitemap
            </a>
          </div>
        </div>
      </div>
    </animated.footer>
  )
}

export default Footer
