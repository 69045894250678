import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useSpring, useTrail, animated } from "react-spring"

const MobileNav = ({ location }) => {
  const [navOpen, setNavOpen] = useState(false)
  const springProps = useSpring({
    opacity: navOpen ? 1 : 0,
    transform: navOpen ? "translateX(0)" : "translateX(-100%)",
  })
  const mobileNavItems = [
    { link: "/music/", text: "Music" },
    { link: "/video/", text: "Video" },
    { link: "/photo/", text: "Photo" },
    { link: "/contact/", text: "Contact" },
  ]
  const trail = useTrail(mobileNavItems.length, {
    opacity: navOpen ? 1 : 0,
    transform: navOpen ? "translateX(0)" : "translateX(-50px)",
    from: { opacity: 0, transform: "translateX(-50px)" },
  })

  const handleToggleNav = () => {
    setNavOpen(!navOpen)
  }

  useEffect(() => {
    setNavOpen(false)
  }, [location])

  return (
    <>
      <button
        className={`${
          navOpen
            ? "mobile-header-nav-hamburger mobile-header-nav-hamburger--open"
            : "mobile-header-nav-hamburger"
        } fixed sm:hidden pl-4 pr-8 py-6 z-40`}
        style={{ top: "20px", left: "16px" }}
        onClick={handleToggleNav}
      >
        <div className="mobile-header-nav-buns">
          <div className="mobile-header-nav-bun" />
          <div className="mobile-header-nav-bun" />
        </div>
      </button>
      <nav
        className="fixed sm:hidden w-full z-20"
        style={{ backgroundColor: "rgba(255,255,255, 0.9)" }}
        role="navigation"
        aria-label="mobile-nav"
      >
        <div className="flex justify-center items-center p-4">
          <Link className="uppercase text-4xl mx-2" to="/">
            Bri Stauss
          </Link>
        </div>
      </nav>
      <animated.div
        style={{
          ...springProps,
          backgroundColor: "rgba(255,255,255, 0.9)",
        }}
        className="fixed inset-0 flex flex-col justify-center items-center z-30"
      >
        {trail.map((props, index) => {
          return (
            <animated.div key={index} style={{ ...props }}>
              <Link
                className="uppercase text-3xl mx-4 my-1 hover-underline-animation"
                to={mobileNavItems[index].link}
              >
                {mobileNavItems[index].text}
              </Link>
            </animated.div>
          )
        })}
      </animated.div>
    </>
  )
}

export default MobileNav
