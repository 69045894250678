module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bri Stauss","short_name":"Bri Stauss","description":"Get your music on","lang":"en","display":"standalone","icon":"src/images/music-icon-min.png","start_url":"/","background_color":"#000000","theme_color":"#000000","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"009eed1d125c34e8d27dca9a23a26a13"},
    },{
      plugin: require('../node_modules/gatsby-plugin-analytics-without-segment/gatsby-browser.js'),
      options: {"plugins":[],"cdnUrl":"https://cdnjs.cloudflare.com/ajax/libs/analytics.js/2.9.1/analytics.min.js","services":{"Google Analytics":{"trackingId":"UA-65880413-1"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
