import React from "react"

import "../styles/index.css"
import Header from "./header"
import Footer from "./footer"

const Layout = (props) => (
  <div className="antialiased flex flex-col w-full h-screen">
    <Header {...props} />
    <main className="flex-1">{props.children}</main>
    <Footer />
  </div>
)

export default Layout
