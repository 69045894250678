import React, { useEffect } from "react"
import Nav from "./nav/nav"
import MobileNav from "./nav/mobileNav"
import { useSpring, animated } from "react-spring"

const Header = (props) => {
  const [springProps, setSpringProps] = useSpring(() => ({
    config: { duration: 50 },
    opacity: 0,
  }))

  useEffect(() => {
    setSpringProps({ opacity: 1 })
  }, [setSpringProps])

  return (
    <animated.header style={{ ...springProps }}>
      <Nav />
      <MobileNav {...props} />
    </animated.header>
  )
}

export default Header
