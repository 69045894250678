import React from "react"
import Layout from "./src/components/layout"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export const wrapPageElement = ({ element, props }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
      <Layout {...props}>{element}</Layout>
    </GoogleReCaptchaProvider>
  )
}
